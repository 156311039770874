<template>
    <v-dialog
            persistent
            v-model="editDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addAudit') : $t('message.updateAudit') + ": " + auditName }}</span>
                <HelpPopUpV2 help-page-component="AddAudit" :light="true" :no-padding="true"></HelpPopUpV2>
            </v-card-title>
            <v-card-text>
                <v-overlay
                        :value="loading.get"
                        absolute
                        opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="auditForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.auditNumber') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-text-field
                                    :value="Audit__title"
                                    autocomplete="off"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    readonly
                                    solo
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-select
                                    :items="auditTypes"
                                    :rules="[...validationRules.required]"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="Audit__type"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.auditYear') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-select
                                    :items="auditYears"
                                    :rules="[...validationRules.required]"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="Audit__audit_year"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.startDate') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    max-width="290px"
                                    min-width="290px"
                                    ref="ncrPreferredEtdMenu"
                                    transition="scale-transition"
                                    v-model="startDateMenu"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            :label="Audit__start_date ? '' : $t('message.required')"
                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                            :value="computedStartDateFormatted"
                                            append-icon="event"
                                            clearable
                                            class="force-text-left"
                                            hint="DD/MM/YY format"
                                            hide-details="auto"
                                            background-color="white"
                                            persistent-hint
                                            solo
                                            v-on="on"
                                            @click:clear="Audit__start_date = null"
                                            @click:append="startDateMenu = true"
                                    />
                                </template>
                                <v-date-picker
                                        first-day-of-week="1"
                                        locale-first-day-of-year="4"
                                        show-week
                                        v-model="Audit__start_date"
                                        @input="startDateMenu = false"
                                />
                            </v-menu>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs4 lg4 md6 pl-5 py-1 class="align-center">{{ $t('message.endDate') }}</v-flex>
                        <v-flex xs8 lg8 md6 py-1>
                            <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    max-width="290px"
                                    min-width="290px"
                                    ref="ncrPreferredEtdMenu"
                                    transition="scale-transition"
                                    v-model="endDateMenu"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            :label="Audit__end_date ? '' : $t('message.required')"
                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                            :value="computedEndDateFormatted"
                                            append-icon="event"
                                            clearable
                                            class="force-text-left"
                                            hint="DD/MM/YY format"
                                            hide-details="auto"
                                            background-color="white"
                                            persistent-hint
                                            solo
                                            v-on="on"
                                            @click:clear="Audit__end_date = null"
                                            @click:append="endDateMenu = true"
                                    />
                                </template>
                                <v-date-picker
                                        first-day-of-week="1"
                                        locale-first-day-of-year="4"
                                        show-week
                                        v-model="Audit__end_date"
                                        @input="endDateMenu = false"
                                />
                            </v-menu>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addAudit()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveAudit()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {formatDate, generateArrayOfYears, getYear, log} from "Helpers/helpers";
import {mapGetters} from "vuex";
import {api} from "Api";

const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddAudit",
    components: { HelpPopUpV2 },
    props: ['auditId','auditName','dialog','updateMode'],
    data() {
        return {
            Audit__id: null,
            Audit__end_date: null,
            Audit__start_date: null,
            Audit__audit_year: null,
            Audit__type: 'fsc',
            auditTypes: [
                {value: 'fsc', text: this.$t('message.fsc')},
                {value: 'pefc', text: this.$t('message.pefc')}
            ],
            dialogs: {
                error: false,
                error_message: ""
            },
            endDateMenu: false,
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            startDateMenu: false,
            validForm: true
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        auditYears () {
            let yearsArr = []
            const years = generateArrayOfYears()
            years.forEach(year => {
                yearsArr.push({
                    value: year,
                    text: year.toString()
                })
            })
            return yearsArr
        },
        Audit__title () {
            let startDate = ''
            let endDate = ''
            if(this.Audit__start_date != null) startDate = this.Audit__start_date.replace(/-/g,'')
            if(this.Audit__end_date != null) endDate = this.Audit__end_date.replace(/-/g,'')
            return ['AUDIT',this.Audit__type.toUpperCase(),startDate,endDate].filter(Boolean).join('-');
        },
        computedEndDateFormatted() {
            return formatDate(this.Audit__end_date)
        },
        computedStartDateFormatted() {
            return formatDate(this.Audit__start_date)
        },
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        addAudit () {
            if(this.$refs.auditForm.validate()) {
                this.loading.add = true

                let audit = {
                    end_date: this.Audit__end_date,
                    start_date: this.Audit__start_date,
                    title: this.Audit__title,
                    type: this.Audit__type,
                    audit_year: this.Audit__audit_year
                }

                const promise = new Promise((resolve, reject)=>{
                    api
                        .post("/fsc-pefc/audits",{
                            audit: audit
                        })
                        .then(response => {
                            if(response.data.status == 'success') {
                                resolve('done')
                            } else {
                                resolve(response.data.message)
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                })

                promise
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.auditAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(status,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch((e) => {
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.auditNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        getAuditToUpdateById (auditId) {
            return new Promise((resolve, reject) => {
                api
                    .get('/fsc-pefc/audits/' + auditId )
                    .then(response => {
                        this.Audit__id = response.data.data[0]['Audit']['id']
                        this.Audit__end_date = response.data.data[0]['Audit']['end_date']
                        this.Audit__start_date = response.data.data[0]['Audit']['start_date']
                        this.Audit__type = response.data.data[0]['Audit']['type']
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        saveAudit () {
            if(this.$refs.auditForm.validate()) {
                this.loading.save = true

                let audit = {
                    end_date: this.Audit__end_date,
                    start_date: this.Audit__start_date,
                    title: this.Audit__title,
                    type: this.Audit__type,
                    audit_year: this.Audit__audit_year
                }

                const promise = new Promise((resolve, reject)=>{
                    api
                        .put("/fsc-pefc/audits/" + this.auditId,{
                            audit: audit
                        })
                        .then(response => {
                            if(response.data.status == 'success') {
                                resolve('done')
                            } else {
                                resolve(response.data.message)
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                })

                promise
                    .then((status) => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.auditUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(status,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    })
                    .catch((e) => {
                        this.$toast.error(e,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            }
        },
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getAuditToUpdateById(this.auditId)
                        .then(() => {
                            this.loading.get = false
                        })
                        .catch((e) => {
                            this.$toast.error(e,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.get = false
                        })
                }
            } else {
                this.Audit__id = null
                this.Audit__end_date = null
                this.Audit__start_date = null
                this.Audit__type = 'fsc'
                const date = new Date()
                this.Audit__audit_year = date.getFullYear()
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        const date = new Date()
        this.Audit__audit_year = date.getFullYear()
    }
}
</script>

<style scoped>

</style>